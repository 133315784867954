import Vue from 'vue'
import Echo from 'laravel-echo'
import { API_URL_WS } from '@/constants.js'

window.io = require('socket.io-client')

const echo = new Echo({
  broadcaster: 'socket.io',
  transports: ['websocket'],
  host: API_URL_WS,
})

Vue.prototype.$echo = echo

window.adminChannelName = 'admin'
