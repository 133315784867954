<template>
  <div class="voyage--table mix">
    <template v-if="inputSearch.length || search">
      <div :class="{ 'search-tr': inputSearch.length || search }" v-for="(item, index) in table.data" :key="item.id">
        <table>
          <tbody>
            <tr
              :class="[
                { 'odd-tr': inputSearch.length || search },
                {
                  'even--tr': (index % 2 !== 0 && inputSearch.length) || (index % 2 !== 0 && search),
                },
              ]"
            >
              <td>
                <span
                  :class="[
                    'bold',
                    { working: parseInt(item.status) === 2 },
                    { cancel: parseInt(item.status) === 3 },
                    { end: parseInt(item.status) === 1 },
                    { 'yellow-end': parseInt(item.status) === 4 },
                    { 'order-complete': parseInt(item.status) === 5 },
                  ]"
                >
                  <router-link v-if="reserve" :to="`/flights/${flightId}/orders/${item.id}?reserve=1`" target="_blank"
                    >№ {{ item.id }}</router-link
                  >
                  <router-link v-else :to="`/flights/${flightId}/orders/${item.id}`" target="_blank"
                    >№ {{ item.id }}</router-link
                  >
                </span>
                <span>{{ item.name }}</span>
              </td>
              <td>
                <span>
                  {{ item.phone }}
                </span>
                <span>
                  {{ item.email }}
                </span>
              </td>
              <td>
                <span class="address">
                  {{ item.delivery_city }}
                  <template v-if="item.delivery_address_full"> , {{ item.delivery_address_full }} </template>
                </span>
              </td>
              <td>
                <span class="bold">Себестоимость</span>
                <span>{{ splitPrice(item.cost_price + '') }} {{ CURRENCY_SYMBOL }}</span>
              </td>
              <td>
                <span class="bold">Наценка</span>
                <span>{{ item.products_overall_charge_sum }} {{ CURRENCY_SYMBOL }}</span>
              </td>
              <td>
                <span class="bold">Курьер</span>
                <span>{{ item.delivery_cost }} {{ CURRENCY_SYMBOL }}</span>
              </td>
              <td>
                <span class="bold">Подъем</span>
                <span>
                  <template v-if="item.delivery_lifting">Да</template>
                  <template v-else>Нет</template>
                </span>
              </td>
              <td>
                <span class="bold">Сборка</span>
                <span>
                  <template v-if="item.delivery_assemble">Да</template>
                  <template v-else>Нет</template>
                </span>
              </td>
              <td>
                <span class="comment">
                  {{ item.comment || '' }}
                </span>
              </td>
            </tr>
          </tbody>
        </table>
        <SearchTable
          v-if="inputSearch.length || search"
          :orderId="item.id"
          :products="item.details || []"
          :class="{ 'even--table': index % 2 === 0 }"
          @openModal="openModal"
        />
      </div>
    </template>
    <template v-else>
      <table>
        <tbody>
          <tr v-for="item in table.data" :key="item.id">
            <td>
              <span
                :class="[
                  'bold',
                  { working: parseInt(item.status) === 2 },
                  { cancel: parseInt(item.status) === 3 },
                  { end: parseInt(item.status) === 1 },
                  { 'yellow-end': parseInt(item.status) === 4 },
                  { 'order-complete': parseInt(item.status) === 5 },
                ]"
              >
                <router-link v-if="reserve" :to="`/flights/${flightId}/orders/${item.id}?reserve=1`" target="_blank"
                  >№ {{ item.id }}</router-link
                >
                <router-link v-else :to="`/flights/${flightId}/orders/${item.id}`" target="_blank"
                  >№ {{ item.id }}</router-link
                >
              </span>
              <span>{{ item.name }}</span>
            </td>
            <td>
              <span>
                {{ item.phone }}
              </span>
              <span>
                {{ item.email }}
              </span>
            </td>
            <td>
              <span class="address">
                {{ item.delivery_city }}
                <template v-if="item.delivery_address_full"> , {{ item.delivery_address_full }} </template>
              </span>
            </td>
            <td>
              <span class="bold">Себестоимость</span>
              <span>{{ splitPrice(item.cost_price + '') }} {{ CURRENCY_SYMBOL }}</span>
            </td>
            <td>
              <span class="bold">Наценка</span>
              <span>{{ item.products_overall_charge_sum }} {{ CURRENCY_SYMBOL }}</span>
            </td>
            <td>
              <span class="bold">Курьер</span>
              <span>{{ item.delivery_cost }} {{ CURRENCY_SYMBOL }}</span>
            </td>
            <td>
              <span class="bold">Подъем</span>
              <span>
                <template v-if="item.delivery_lifting">Да</template>
                <template v-else>Нет</template>
              </span>
            </td>
            <td>
              <span class="bold">Сборка</span>
              <span>
                <template v-if="item.delivery_assemble">Да</template>
                <template v-else>Нет</template>
              </span>
            </td>
            <td>
              <span class="comment">
                {{ item.comment || '' }}
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </template>
    <Pagination :type="0" :eventId="flightId" v-if="table.last_page > 1" />
    <Modal v-if="show" type="remove" @closePopup="closeModal">
      <RemoveModal
        type="flight"
        orderType="flight"
        :query="query"
        :flightId="flightId"
        :search="search"
        :searchInput="inputSearch"
        :orderId="orderId"
        :removeId="removeId"
        @closeRemoveModal="closeModal"
      />
    </Modal>
    <div class="blocker" v-if="show" @click="closeModal"></div>
  </div>
</template>
<script>
import Pagination from '@/components/tools/Pagination.vue'
import SearchTable from '@/components/table/SearchTable.vue'
import Modal from '@/components/tools/Modal.vue'
import RemoveModal from '@/components/tools/modals/RemoveModal.vue'
import { CURRENCY_SYMBOL } from '@/constants.js'

export default {
  components: {
    Pagination,
    SearchTable,
    Modal,
    RemoveModal,
  },

  props: {
    reserve: {
      type: Number,
      default: 0,
    },
    table: {
      type: Object,
      default: () => {},
    },
    flightId: {
      type: Number,
      default: 0,
    },
    search: {
      type: Boolean,
      default: false,
    },
    query: {
      type: String,
      default: '',
    },
  },

  computed: {
    inputSearch() {
      return this.$store.getters['flightSearch']
    },
  },

  data: () => ({
    CURRENCY_SYMBOL,
    show: false,
    removeId: 0,
    orderId: 0,
  }),

  methods: {
    splitPrice(x) {
      if (typeof x === 'string') {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
      } else {
        return x
      }
    },

    openModal(data) {
      this.removeId = data.removeId
      this.orderId = data.orderId
      this.show = true
    },

    closeModal() {
      this.show = false
    },
  },
}
</script>

<style lang="scss" scoped>
td {
  &:hover {
    text-decoration: none !important;
  }
}
.bold {
  a {
    text-decoration: none;
    color: inherit;
  }
}
.odd-tr {
  background-color: #fff !important;
}
.even--tr {
  background-color: #f7f7f7 !important;
}
.voyage--table {
  tr {
    background-color: #fff;
    border-bottom: 0.5px solid #dadada;
    &:last-child {
      border: none;
    }
  }
}
.yellow-end {
  color: #f1c40f;
}
</style>
