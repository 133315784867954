<template>
  <v-autocomplete v-model="categoryId" @change="change" box clearable :label="label" :items="categoriesCombobox">
    <template v-slot:item="{ item }">
      <span :class="`indent-${item.level}`">{{ item.text }}</span>
    </template>
  </v-autocomplete>
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: {
    value: Number,
    label: {
      type: String,
      default: 'Категория',
    },
  },

  data: () => ({
    categoryId: null,
  }),

  watch: {
    value(newValue) {
      this.categoryId = newValue
    },
  },

  computed: {
    ...mapState({ categoriesCombobox: (state) => state.categories.categories }),
  },

  methods: {
    change() {
      this.$emit('input', this.categoryId)
      this.$emit('change')
    },
  },
}
</script>

<style scoped>
.indent-0 {
  padding-left: 0;
}

.indent-1 {
  padding-left: 20px;
}

.indent-2 {
  padding-left: 40px;
}

.indent-3 {
  padding-left: 60px;
}

.indent-4 {
  padding-left: 80px;
}
</style>
