<template>
  <div class="order-page">
    <template v-if="order">
      <div style="border-bottom: 1px solid #dadada" class="order-page__header">
        <div class="ya-container">
          <div style="border: 0; padding: 0" class="ya-table__header">
            <div>
              <span
                :class="[
                  'order-page__header-id',
                  { working: parseInt(order.status) === 2 },
                  { cancel: parseInt(order.status) === 3 },
                  { end: parseInt(order.status) === 1 },
                  { 'yellow-order': parseInt(order.status) === 4 },
                  { 'order-complete': parseInt(order.status) === 5 },
                ]"
              >
                № {{ order.id }} ({{ order.order_num }})
              </span>
              <span class="order-page__header-info">{{ order.created_at }}</span>
              <span class="ya-table__status">
                <template v-if="parseInt(order.status) === 1"> (В обработке) </template>
                <template v-if="parseInt(order.status) === 2"> (Подтвержден) </template>
                <template v-if="parseInt(order.status) === 3"> (Отменён) </template>
              </span>
              <span class="ya-table__title">
                <a :href="`/flights?open=${flightId}`" v-if="is_reserve"> Резерв </a>
                <a :href="`/flights?open=${flightId}`" v-else> Рейс № {{ flightId }} </a>
              </span>
              <span class="ya-table__date" v-if="!is_reserve">{{ order.delivery_date }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="order-page__info">
        <div class="ya-container">
          <div class="order-page__info-grid">
            <div class="order-page__col">
              <div class="order-page__input">
                <input type="text" placeholder="ФИО" :value="order.name" @change="changeName" />
              </div>
              <div class="order-page__input">
                <input
                  v-model="phone"
                  v-mask="PHONE_MASK"
                  @change="changePhone"
                  type="tel"
                  placeholder="Телефон"
                  autocomplete="off"
                />
                <img class="masked-copy" src="/images/copy.svg" alt="copy" @click="copyPhone" />
              </div>
              <div class="order-page__input">
                <textarea
                  type="text"
                  placeholder="Дополнительные контакты"
                  :value="order.contacts"
                  @change="changeContacts"
                />
              </div>
            </div>
            <div class="order-page__col">
              <div class="order-page__wrap">
                <div class="order-page__row">
                  <div class="order-page__input">
                    <input type="text" placeholder="Email" :value="order.email" @change="changeEmail" />
                  </div>

                  <CitiesList
                    :type="type"
                    :flightId="flightId"
                    :without="without"
                    :id="order.id"
                    :cities="cities"
                    :user-city="order.delivery_city"
                  />
                </div>
                <div class="order-page__row">
                  <div class="order-page__input promocode-input">
                    <input type="text" placeholder="Промокод" :value="order.promo_code" @change="changePromocode" />
                    <div class="promocode-bar">
                      <template v-if="promoCodeInfo">{{ promoCodeInfo.discount }}</template>
                    </div>
                  </div>
                  <div class="order-page__input">
                    <input
                      type="text"
                      placeholder="Адрес доставки"
                      :value="order.delivery_address_full"
                      @change="changeAddress"
                    />
                  </div>
                </div>
              </div>
              <div class="order-page__col-bottom">
                <div class="order-page__checkbox">
                  <h5>Коррекция</h5>
                  <div class="order-page__check">
                    <input type="number" :placeholder="CURRENCY_SYMBOL" :value="order.correction" @change="changeCorrection" />
                  </div>
                </div>
                <div class="order-page__checkbox">
                  <h5>Подъём</h5>
                  <div class="order-page__check number-input">
                    <input
                      type="checkbox"
                      :id="`c1-${order.id}`"
                      :checked="order.delivery_lifting"
                      @change="changeLifting"
                    />
                    <label :for="`c1-${order.id}`"></label>
                    <div class="order-page__lifting">
                      <div class="order-page__lifting-input">
                        <input
                          v-if="order.delivery_lifting"
                          type="number"
                          :placeholder="CURRENCY_SYMBOL"
                          :disabled="!order.delivery_lifting"
                          :value="order.delivery_lifting_cost"
                          @change="changeLiftingCost"
                        />
                        <input v-if="!order.delivery_lifting" type="number" :placeholder="CURRENCY_SYMBOL" disabled="disabled" />
                      </div>
                      <div class="order-page__lifting-wrap">
                        <input
                          v-if="order.delivery_lifting"
                          type="number"
                          placeholder="гр"
                          :disabled="!order.delivery_lifting"
                          :value="order.loaders_count"
                          @change="changeLoadersCount"
                        />
                        <input v-if="!order.delivery_lifting" type="number" placeholder="гр" disabled="disabled" />
                        <input
                          v-if="order.delivery_lifting"
                          type="number"
                          placeholder="пом"
                          :disabled="!order.delivery_lifting"
                          :value="order.helpers_count"
                          @change="changeHelpersCount"
                        />
                        <input v-if="!order.delivery_lifting" type="number" placeholder="пом" disabled="disabled" />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="order-page__checkbox">
                  <h5>Сборка</h5>
                  <div class="order-page__check number-input">
                    <input
                      type="checkbox"
                      :id="`assemble-${order.id}`"
                      :checked="order.delivery_assemble"
                      @change="changeAssemble"
                    />
                    <label :for="`assemble-${order.id}`"></label>
                    <input
                      type="number"
                      :disabled="!order.delivery_assemble"
                      :value="order.delivery_assemble_cost"
                      @change="changeAssembleCost"
                      :placeholder="CURRENCY_SYMBOL"
                    />
                  </div>
                </div>
                <div class="order-page__checkbox">
                  <h5>Курьер</h5>
                  <div class="order-page__check">
                    <input type="number" :placeholder="CURRENCY_SYMBOL" :value="order.delivery_cost" @change="changeDeliveryCost" />
                  </div>
                </div>
              </div>
            </div>
            <div class="order-page__col">
              <div class="order-page__input">
                <textarea
                  class="comment-area"
                  type="text"
                  placeholder="Комментарий заказчика"
                  :value="order.comment"
                  @change="changeComment"
                />
              </div>
            </div>
            <div class="order-page__col total-col">
              <div class="order-page__total">
                <h3>Итог</h3>
                <p>{{ splitPrice(order.total + '' || 0) }} {{ CURRENCY_SYMBOL }}</p>
              </div>
              <div class="order-page__input">
                <textarea
                  class="order-comment"
                  type="text"
                  placeholder="Введите комментарий к заказу"
                  :value="order.admin_comment"
                  @change="changeAdminComment"
                />
              </div>
            </div>

            <Buttons
              :index="index"
              :without="$route.params.id"
              :type="type"
              :id="order.id"
              :flightId="flightId"
              @openTransfer="openTransfer"
              @openWaybillModal="openWaybillModal"
              @sendOrderToEmail="openSendModal"
            />
          </div>
        </div>
      </div>

      <template v-if="type === 'single'">
        <Table
          :id="order.id"
          :order="order"
          :products="products"
          :exchange="order.exchange"
          @openReplace="openReplace"
          @openPopup="openModal"
          @removeElements="removeElements"
          @removeElement="removeElement"
          @openTransfer="openTransfer"
          @openTransferProducts="openTransferProducts"
        />
      </template>

      <template v-if="type === 'other'">
        <Table
          :without="without"
          :orderType="type"
          :id="order.id"
          :order="order"
          :products="order.details || []"
          :exchange="order.exchange"
          :flightId="flightId"
          @openReplace="openReplace"
          @openPopup="openModal"
          @removeElements="removeElements"
          @removeElement="removeElement"
          @openTransfer="openTransfer"
          @openTransferProducts="openTransferProducts"
        />
      </template>

      <Modal v-if="showRouteModal" @closePopup="closeModal">
        <AddOrderToWaybill :order="order" :flightId="flightId" @closeModal="closeModal" />
      </Modal>

      <Modal v-if="visible" @closePopup="closeModal">
        <CreateItem :flightId="flightId" :without="without" :orderType="type" :id="order.id" @closeModal="closeModal" />
      </Modal>

      <Modal v-if="replace" @closePopup="closeModal">
        <CreateItem
          :flightId="flightId"
          :without="without"
          :orderType="type"
          type="replace"
          :productId="parseInt(productId)"
          :id="order.id"
          @closeModal="closeModal"
        />
      </Modal>

      <Modal v-if="show" type="remove" @closePopup="closeModal">
        <RemoveModal
          :flightId="flightId"
          :without="without"
          :orderType="type"
          :orderId="order.id"
          :selected="selected"
          :removeId="removeId"
          @closeRemoveModal="closeModal"
        />
      </Modal>

      <Modal v-if="resence" @closePopup="closeModal">
        <Transfer
          :type="transferType"
          :flightId="parseInt(flightId)"
          :currentFlightId="parseInt($route.params.flightId)"
          :selected="selected"
          :orderId="parseInt(order.id)"
          @closeTransfer="closeModal"
        />
      </Modal>

      <Modal v-if="send_order_visible" @closePopup="closeModal">
        <h2 class="order-modal__title">Отправить акт ?</h2>
        <div class="order-modal__bottom">
          <button class="button primary" @click="sendOrder">Да</button>
          <button class="outlined" @click="closeModal">Отмена</button>
        </div>
      </Modal>

      <div
        class="blocker"
        v-if="visible || show || resence || replace || showRouteModal || send_order_visible"
        @click="closeModal"
      ></div>
    </template>
  </div>
</template>

<script>
import Modal from '@/components/tools/Modal.vue'
import RemoveModal from '@/components/tools/modals/RemoveModal.vue'
import AddOrderToWaybill from '@/components/tools/modals/AddOrderToWaybill.vue'
import CreateItem from '@/components/tools/modals/CreateItem.vue'
import Transfer from '@/components/tools/modals/Transfer.vue'
import CitiesList from '@/components/order/CitiesList.vue'
import Buttons from '@/components/order/Buttons.vue'
import Table from '@/components/order/Table.vue'
import { PHONE_CODE, PHONE_MASK } from '@/constants.js'

export default {
  components: {
    CitiesList,
    Buttons,
    Table,
    Modal,
    CreateItem,
    RemoveModal,
    AddOrderToWaybill,
    Transfer,
  },

  props: {
    type: {
      type: String,
      default: 'single',
    },
    index: {
      type: Number,
      default: 0,
    },
    order: {
      type: Object,
      default: function () {
        return {}
      },
    },
    without: {
      type: String,
      defaul: '0',
    },
    flightId: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      PHONE_CODE,
      PHONE_MASK,
      is_reserve: false,
      resence: false,
      replace: false,
      show: false,
      visible: false,
      send_order_visible: false,
      showRouteModal: false,
      cities: [],
      removeId: null,
      selected: [],
      transferType: 'transfer',
      productId: 0,
      phone: '',
    }
  },

  computed: {
    products() {
      if (this.type === 'single') {
        return this.$store.getters['orderProducts']
      } else {
        return []
      }
    },

    promoCodeInfo() {
      return this.$store.getters['promoCodeInfo']
    },
  },

  watch: {
    order(newValue) {
      this.phone = newValue.phone
    },

    phone(newValue) {
      if (newValue.length < PHONE_CODE.length) {
        this.phone = PHONE_CODE
      }
    },
  },

  created() {
    this.redirectToCurrentFlight()
  },

  mounted() {
    const reserve = this.$route.query.reserve
    if (reserve) {
      this.is_reserve = reserve
    }

    this.getCities()

    this.$echo.channel(window.adminChannelName).listen('.OrderUpdated', (e) => {
      if (e.order.id === this.order.id && this.type === 'other') {
        this.$emit('otherOrderUpdate', e)
      } else if (e.order.id === this.order.id) {
        this.$emit('orderUpdate', e)
      }
    })

    this.$echo.channel(window.adminChannelName).listen('.OrderProductAdded', (e) => {
      if (e.product.order_id === this.order.id && this.type === 'single') {
        this.$emit('productsOrderUpdate', 'single')
      } else if (e.product.order_id === this.order.id && this.type === 'other') {
        this.$emit('productsOtherOrderUpdate', 'other')
      }
    })

    this.$echo.channel(window.adminChannelName).listen('.OrderProductsDeleted', (e) => {
      if (e.products[0].order_id === this.order.id && this.type === 'single') {
        this.$emit('productsOrderUpdate', 'single')
      } else if (e.products[0].order_id === this.order.id && this.type === 'other') {
        this.$emit('productsOtherOrderUpdate', 'other')
      }
    })

    this.$echo.channel(window.adminChannelName).listen('.OrderProductUpdated', (e) => {
      this.$store.dispatch('orderUpdateProduct', e)
    })

    this.$echo.channel(window.adminChannelName).listen('.OrderProductDeleted', () => {
      if (this.type === 'single') {
        this.$store.dispatch('getOrderProducts', this.order.id)
      } else if (this.type === 'other') {
        this.$store.dispatch('getOtherOrders', {
          flightId: this.flightId,
          without: this.without,
        })
      }
    })
  },

  methods: {
    async getCities() {
      const response = await this.axios.get('/api/site/orders/cities')
      response.data.data.value.forEach((city) => {
        this.cities.push(city.name)
      })
    },

    redirectToCurrentFlight() {
      const order_id = parseInt(this.$route.params.id)
      const url_flight_id = parseInt(this.$route.params.flightId)

      this.axios
        .get(`/api/admin/orders/${order_id}?withFlight=1`)
        .then((res) => {
          const flight_id = res.data.data.flight.id
          if (url_flight_id !== flight_id) {
            window.location.href = `/flights/${flight_id}/orders/${order_id}`
          }
        })
        .catch((err) => console.log(err.message))
    },

    async sendOrder() {
      await this.axios
        .post(`/api/admin/orders/${this.order.id}/report/email`)
        .then(() => (this.send_order_visible = false))
        .catch((err) => console.log(err.message))
    },

    openTransferProducts(data) {
      this.resence = true
      this.selected = data.selected
      this.transferType = data.type
    },

    async changeName(e) {
      const name = e.target.value || '0'
      await this.$store.dispatch('patchOrder', {
        flightId: this.flightId,
        without: this.without,
        type: this.type,
        id: this.order.id,
        params: { name },
      })
      e.target.blur()
    },

    async changeEmail(e) {
      const email = e.target.value
      await this.$store.dispatch('patchOrder', {
        flightId: this.flightId,
        without: this.without,
        type: this.type,
        id: this.order.id,
        params: { email },
      })
      e.target.blur()
    },

    async changePhone(e) {
      const phone = e.target.value
      await this.$store.dispatch('patchOrder', {
        flightId: this.flightId,
        without: this.without,
        type: this.type,
        id: this.order.id,
        params: { phone },
      })
      e.target.blur()
    },

    async changePromocode(e) {
      const promo_code = e.target.value || '0'
      await this.$store.dispatch('patchOrder', {
        flightId: this.flightId,
        without: this.without,
        type: this.type,
        id: this.order.id,
        params: { promo_code },
      })
      await this.$store.dispatch('checkPromocode')
      e.target.blur()
    },

    async changeAddress(e) {
      const delivery_address_full = e.target.value
      await this.$store.dispatch('patchOrder', {
        flightId: this.flightId,
        without: this.without,
        type: this.type,
        id: this.order.id,
        params: { delivery_address_full },
      })
      e.target.blur()
    },

    async changeComment(e) {
      const comment = e.target.value || '0'
      this.$store.dispatch('patchOrder', {
        flightId: this.flightId,
        without: this.without,
        type: this.type,
        id: this.order.id,
        params: { comment },
      })
    },

    async changeAdminComment(e) {
      const admin_comment = e.target.value || '0'
      await this.$store.dispatch('patchOrder', {
        flightId: this.flightId,
        without: this.without,
        type: this.type,
        id: this.order.id,
        params: { admin_comment },
      })
    },

    async changeCorrection(e) {
      const params = { correction: parseFloat(e.target.value).toFixed(2) }

      await this.$store.dispatch('patchOrder', {
        flightId: this.flightId,
        without: this.without,
        type: this.type,
        id: this.order.id,
        params,
      })
      e.target.blur()
    },

    async changeAssemble(e) {
      const delivery_assemble = e.target.checked
      await this.$store.dispatch('patchOrder', {
        flightId: this.flightId,
        without: this.without,
        type: this.type,
        id: this.order.id,
        params: { delivery_assemble },
      })
      e.target.blur()
    },

    copyPhone() {
      const text = this.order.phone

      try {
        navigator.clipboard.writeText(text)
      } catch (e) {
        console.log(e)
      }
    },

    async changeAssembleCost(e) {
      const delivery_assemble_cost = parseInt(e.target.value)
      await this.$store.dispatch('patchOrder', {
        flightId: this.flightId,
        without: this.without,
        type: this.type,
        id: this.order.id,
        params: { delivery_assemble_cost },
      })
      e.target.blur()
    },

    async changeDeliveryCost(e) {
      const delivery_cost = parseInt(e.target.value)
      const is_delivery_cost_unchangeable = false
      let params = {}

      if (delivery_cost || delivery_cost === 0) {
        params = { delivery_cost }
      } else {
        params = { is_delivery_cost_unchangeable }
      }

      await this.$store.dispatch('patchOrder', {
        flightId: this.flightId,
        without: this.without,
        type: this.type,
        id: this.order.id,
        params,
      })
      e.target.blur()
    },

    changeLifting(e) {
      const delivery_lifting = e.target.checked
      this.$store.dispatch('patchOrder', {
        flightId: this.flightId,
        without: this.without,
        type: this.type,
        id: this.order.id,
        params: { delivery_lifting },
      })
      e.target.blur()
    },

    changeLiftingCost(e) {
      const delivery_lifting_cost = parseInt(e.target.value)
      this.$store.dispatch('patchOrder', {
        flightId: this.flightId,
        without: this.without,
        type: this.type,
        id: this.order.id,
        params: { delivery_lifting_cost },
      })
      e.target.blur()
    },

    changeHelpersCount(e) {
      const helpers_count = parseInt(e.target.value)
      this.$store.dispatch('patchOrder', {
        flightId: this.flightId,
        without: this.without,
        type: this.type,
        id: this.order.id,
        params: { helpers_count },
      })
      e.target.blur()
    },

    changeLoadersCount(e) {
      const loaders_count = parseInt(e.target.value)
      this.$store.dispatch('patchOrder', {
        flightId: this.flightId,
        without: this.without,
        type: this.type,
        id: this.order.id,
        params: { loaders_count },
      })
      e.target.blur()
    },

    changeContacts(e) {
      const contacts = e.target.value || '0'
      this.$store.dispatch('patchOrder', {
        flightId: this.flightId,
        without: this.without,
        type: this.type,
        id: this.order.id,
        params: { contacts },
      })
      e.target.blur()
    },

    openTransfer() {
      this.resence = true
    },

    openWaybillModal() {
      this.showRouteModal = true
    },

    removeElement(id) {
      this.show = true
      this.removeId = id
    },

    removeElements(products) {
      if (!products.length) return

      this.show = true
      this.removeId = null
      this.selected = products
    },

    openModal() {
      this.visible = true
    },

    openSendModal() {
      this.send_order_visible = true
    },

    openReplace(product_id) {
      this.productId = product_id
      this.replace = true
    },

    closeModal() {
      this.$store.dispatch('clearVendorProduct')
      this.resence = false
      this.show = false
      this.visible = false
      this.replace = false
      this.showRouteModal = false
      this.send_order_visible = false
    },

    splitPrice(x) {
      if (typeof x === 'string') {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
      } else {
        return x
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.ya-table__title {
  a {
    text-decoration: none;
    color: inherit;
  }
}
.promocode-input {
  display: flex;
  input {
    max-width: 144px;
    width: 100%;
    min-width: 144px;
    background-color: #fff;
  }
  .promocode-bar {
    background: #ffffff;
    border: 1px solid #ededed;
    border-left: none;
    max-width: 77px;
    width: 100%;
    height: 40px;
    padding: 8px;
    text-align: center;
    font-size: 14px;
    line-height: 160%;
    color: #000;
  }
}
.order-page__input {
  position: relative;
  .masked-copy {
    position: absolute;
    right: 11px;
    top: 11px;
    cursor: pointer;
    transition: opacity 0.2s ease-in-out;
    opacity: 1;
    &:active {
      transition: opacity 0.2s ease-in-out;
      opacity: 0.8;
    }
  }
}
.yellow-order {
  color: #f1c40f;
}
.order-page__lifting {
  display: flex;
  flex-wrap: wrap;
  &-input {
    input {
      padding: 0 5px !important;
      border-bottom: 0 !important;
      width: 90px !important;
      height: 19px !important;
    }
  }
  &-wrap {
    display: flex;
    input {
      height: 19px !important;
      width: 45px;
      min-width: unset !important;
      padding: 0 5px !important;
      &:nth-child(2) {
        border-left: 1px solid #ededed;
      }
    }
  }
}
.order-modal {
  &__title {
    text-align: center;
    font-weight: 500;
    margin-bottom: 34px;
    font-size: 24px;
    line-height: 28px;
  }
  &__bottom {
    display: flex;
    button {
      width: 100%;
      height: 40px;
      &:first-child {
        margin-right: 10px;
      }
    }
  }
}
</style>
