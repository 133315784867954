<template>
  <div class="order-page">
    <div style="border-bottom: 1px solid #dadada" class="order-page__header">
      <div class="ya-container">
        <div style="border: 0; padding: 0" class="ya-table__header">
          <div>
            <span class="order-page__header-id end">Новый заказ</span>
          </div>
        </div>
      </div>
    </div>
    <div class="order-page__info">
      <div class="ya-container">
        <div class="order-page__info-grid">
          <div class="order-page__col">
            <div class="order-page__input">
              <input type="text" placeholder="ФИО" v-model.trim="order.name" />
            </div>
            <div class="order-page__input">
              <input v-model="phone" v-mask="PHONE_MASK" type="tel" placeholder="Телефон" autocomplete="off" />
            </div>
            <div class="order-page__input">
              <textarea type="text" placeholder="Дополнительные контакты" v-model.trim="order.contacts" />
            </div>
          </div>
          <div class="order-page__col">
            <div class="order-page__wrap">
              <div class="order-page__row">
                <div class="order-page__input">
                  <input type="text" placeholder="Email" v-model.trim="order.email" />
                </div>
                <CitiesList :cities="cities" @currentCity="saveCity" />
              </div>
              <div class="order-page__row">
                <div class="order-page__input promocode-input">
                  <input type="text" placeholder="Промокод" v-model.trim="order.promo_code" />
                  <div class="promocode-bar"></div>
                </div>
                <div class="order-page__input">
                  <input type="text" placeholder="Адрес доставки" v-model.trim="order.delivery_address" />
                </div>
              </div>
            </div>
            <div class="order-page__col-bottom">
              <div class="order-page__checkbox">
                <h5>Подъем</h5>
                <div class="order-page__check number-input">
                  <input type="checkbox" id="c1" v-model="order.delivery_lifting" />
                  <label for="c1"></label>
                  <input
                    type="number"
                    :placeholder="CURRENCY_SYMBOL"
                    :disabled="!order.delivery_lifting"
                    v-model="order.delivery_lifting_cost"
                  />
                </div>
              </div>
              <div class="order-page__checkbox">
                <h5>Сборка</h5>
                <div class="order-page__check number-input">
                  <input type="checkbox" id="c2" v-model="order.delivery_assemble" />
                  <label for="c2"></label>
                  <input
                    type="number"
                    :disabled="!order.delivery_assemble"
                    v-model="order.delivery_assemble_cost"
                    :placeholder="CURRENCY_SYMBOL"
                  />
                </div>
              </div>
              <div class="order-page__checkbox">
                <h5>Курьер</h5>
                <div class="order-page__check">
                  <input type="number" :placeholder="CURRENCY_SYMBOL" v-model="order.delivery_cost" />
                </div>
              </div>
              <div class="order-page__checkbox">
                <h5>Комиссия</h5>
                <div class="order-page__check">
                  <input type="number" :placeholder="CURRENCY_SYMBOL" v-model.number="order.commission" />
                </div>
              </div>
              <div class="order-page__checkbox">
                <h5>Фикс. ком.</h5>
                <div class="order-page__check">
                  <input type="number" :placeholder="CURRENCY_SYMBOL" v-model.number="order.fix_commission" />
                </div>
              </div>
            </div>
          </div>
          <div class="order-page__col">
            <div class="order-page__input">
              <textarea
                class="comment-area"
                type="text"
                placeholder="Комментарий заказчика"
                v-model.trim="order.comment"
              />
            </div>
          </div>
          <div class="order-page__col total-col">
            <div class="order-page__total">
              <h3>Итог</h3>
              <p>0 {{ CURRENCY_SYMBOL }}</p>
            </div>
            <div class="order-page__input">
              <textarea
                class="order-comment"
                type="text"
                placeholder="Введите комментарий к заказу"
                v-model.trim="order.admin_comment"
              />
            </div>
          </div>
          <button :class="['create-order_btn', { loading }]" :disabled="disabled" @click="createNewOrder(order)">
            Создать заказ
            <div class="my-loader" v-if="loading"></div>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CitiesList from '@/components/order/CitiesList.vue'
import { PHONE_CODE, PHONE_MASK, CURRENCY_SYMBOL } from '@/constants.js'

export default {
  components: {
    CitiesList,
  },

  computed: {
    disabled() {
      if (
        this.order.email.length &&
        this.order.phone.length === 17 &&
        this.order.delivery_address.length &&
        this.order.delivery_city.length
      ) {
        return false
      } else {
        return true
      }
    },
  },

  data() {
    return {
      PHONE_CODE,
      PHONE_MASK,
      CURRENCY_SYMBOL,
      loading: false,
      cities: [],
      order: {
        email: '',
        name: '',
        phone: '',
        promo_code: '',
        delivery_address: '',
        contacts: '',
        comment: '',
        commission: 0,
        admin_comment: '',
        delivery_assemble: false,
        delivery_assemble_cost: 0,
        delivery_lifting: false,
        delivery_lifting_cost: 0,
        delivery_cost: 0,
        fix_commission: 0,
        delivery_city: '',
        delivery_date: '',
      },
      phone: PHONE_CODE,
    }
  },

  watch: {
    phone(newValue) {
      if (newValue.length < PHONE_CODE.length) {
        this.phone = PHONE_CODE
        this.order.phone = PHONE_CODE
      } else {
        this.order.phone = newValue
      }
    },
  },

  mounted() {
    this.getCities()
    this.getFlight()
  },

  methods: {
    async getFlight() {
      await this.axios
        .get(`/api/admin/flights/${this.$route.params.flightId}`)
        .then((res) => (this.order.delivery_date = res.data.data.date))
        .catch((err) => console.log(err.message))
    },

    async createNewOrder(data) {
      this.loading = true
      let link = `/api/admin/orders`

      await this.axios
        .post(link, {
          email: data.email,
          name: data.name || '0',
          phone: data.phone,
          contacts: data.contacts || '0',
          commission: data.commission,
          promo_code: data.promo_code.length ? data.promo_code : '0',
          delivery_address: data.delivery_address,
          delivery_address_full: data.delivery_address,
          comment: data.comment.length ? data.comment : '0',
          admin_comment: data.admin_comment || '0',
          delivery_assemble: data.delivery_assemble,
          delivery_assemble_cost: data.delivery_assemble ? data.delivery_assemble_cost : 0,
          delivery_lifting: data.delivery_lifting,
          delivery_lifting_cost: data.delivery_lifting ? data.delivery_lifting_cost : 0,
          delivery_cost: data.delivery_cost,
          fix_commission: data.fix_commission,
          delivery_city: data.delivery_city,
          delivery_date: data.delivery_date,
        })
        .then((res) => {
          const result = res.data.data
          this.loading = false
          location.href = `/flights/${this.$route.params.flightId}/orders/${result.id}`
        })
        .catch((err) => console.log(err.message))
    },

    saveCity(city) {
      this.order.delivery_city = city
    },

    async getCities() {
      const response = await this.axios.get('/api/site/orders/cities')
      response.data.data.value.forEach((city) => {
        this.cities.push(city.name)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.order-page__col-bottom {
  grid-template-columns: repeat(5, 1fr);
  gap: 8px;
}
.create-order_btn {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #04b200;
  border-radius: 37px;
  padding: 14px 7px;
  color: #fff;
  font-size: 12px;
  line-height: 14px;
  width: 119px;
  height: 29px;
  margin: auto 0 30px 20px;
  transition: background 0.2s ease-in-out;

  &:hover {
    transition: background 0.2s ease-in-out;
    background: #038000;
  }

  &:active {
    transition: background 0.2s ease-in-out;
    background: #04b200;
  }

  &:disabled {
    border: 1px solid #828282;
    background: transparent;
    color: #828282;
  }

  &.loading {
    position: relative;
    font-size: 0;

    .my-loader {
      top: 6px;
      left: 0;
      right: 0;
      margin: 0 auto;
      border-color: rgba(#fff, 0.2);
      border-top-color: #fff;
    }
  }
}
</style>
