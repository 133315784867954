<template>
  <v-container fluid>
    <v-card class="mb-5">
      <v-toolbar flat>
        <v-toolbar-title>Предупреждение на главной странице</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-text-field label="Заголовок" v-model="mainPageWarning.title"></v-text-field>
        <v-text-field label="Текст" v-model="mainPageWarning.description"></v-text-field>
        <v-checkbox label="Включено" v-model="mainPageWarning.visible"></v-checkbox>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="saveMainPageWarning()" color="info">Сохранить</v-btn>
      </v-card-actions>
    </v-card>

    <v-card class="mb-5">
      <v-toolbar flat>
        <v-toolbar-title>Преимущества</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-text-field
          v-for="(plus, index) in pluses"
          :key="index"
          :label="`Строка ${index + 1}`"
          @click:append-outer="removePlus(plus)"
          append-outer-icon="cancel"
          v-model="plus.plus"
        ></v-text-field>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="savePluses()" color="info">Сохранить</v-btn>
        <v-btn @click="addPlus()" color="success">Добавить</v-btn>
      </v-card-actions>
    </v-card>

    <v-card class="mb-5">
      <v-toolbar flat>
        <v-toolbar-title>Популярные категории</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-container fluid grid-list-md>
          <v-layout row wrap>
            <v-flex v-for="i in [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]" :key="i" xs12 sm6 md4 lg3>
              <v-card>
                <div class="img-placeholder" v-if="!imgSrc[i]">
                  <v-btn fab dark color="grey" @click="openFileDialog(i)">
                    <v-icon dark>add</v-icon>
                  </v-btn>
                  <input v-show="false" :ref="`file-${i}`" type="file" @change="setCategoryImage(i, $event)" />
                </div>
                <v-img v-if="imgSrc[i]" :src="imgSrc[i]" height="150px"></v-img>
                <v-btn v-if="imgSrc[i]" flat icon color="red" @click="deleteCategoryImage(i)" class="img-del-button">
                  <v-icon>clear</v-icon>
                </v-btn>
                <v-card-actions>
                  <category-autocomplete v-model="selectedCategory[i]" @change="changeCategory(i)" />
                </v-card-actions>
              </v-card>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import CategoryAutocomplete from '@/components/inputs/CategoryAutocomplete'

export default {
  components: { CategoryAutocomplete },

  data: function () {
    return {
      loading: false,
      mainPageWarning: {},
      pluses: [],
      selectedCategory: [],
      imgSrc: [],
      imgFile: [],
      popularCategories: [],
    }
  },

  mounted() {
    this.getMainPageWarning()
    this.getPluses()
    this.getPopularCategroies()
  },

  methods: {
    getMainPageWarning() {
      this.axios.get('/api/admin/warning/get').then((response) => {
        this.mainPageWarning = response.data
      })
    },

    saveMainPageWarning() {
      this.axios.post('/api/admin/warning/save', this.mainPageWarning).then((response) => {
        this.mainPageWarning = response.data
      })
    },

    getPluses() {
      this.axios.get('/api/admin/pluses/list').then((response) => {
        this.pluses = response.data
      })
    },

    async getPopularCategroies() {
      const response = await this.axios.get('/api/admin/categories/popular')
      this.popularCategories = response.data.data
      this.setDefaultPopularCategories()
    },

    setDefaultPopularCategories() {
      for (let i = 0; i <= 9 && i < this.popularCategories.length; i++) {
        this.$set(this.selectedCategory, i, this.popularCategories[i].cat_id)
        this.$set(this.imgSrc, i, this.popularCategories[i].image_url)
      }
    },

    removePlus(plus) {
      if (plus.id && !window.confirm('Вы уверены?')) {
        return
      }

      if (plus.id) {
        this.axios.post('/api/admin/pluses/delete', { id: plus.id }).then(() => {
          this.getPluses()
        })
      } else {
        this.getPluses()
      }
    },

    addPlus() {
      this.pluses.push({
        plus: '',
        position: 100,
      })
    },

    savePluses() {
      this.axios
        .post('/api/admin/pluses/save', {
          pluses: this.pluses,
        })
        .then(() => {
          this.getPluses()
        })
    },

    openFileDialog(i) {
      this.$refs[`file-${i}`][0].click()
    },

    async setCategoryImage(i, event) {
      this.$set(this.imgFile, i, event.target.files[0])
      this.$set(this.imgSrc, i, URL.createObjectURL(event.target.files[0]))
      const formData = new FormData()
      formData.append('image', event.target.files[0], event.target.files[0].name)
      await this.axios.post(`/api/admin/categories/popular/${this.popularCategories[i].id}`, formData)
    },

    async deleteCategoryImage(i) {
      this.$set(this.imgFile, i, null)
      this.$set(this.imgSrc, i, null)
      const formData = new FormData()
      formData.append('image', '')
      await this.axios.post(`/api/admin/categories/popular/${this.popularCategories[i].id}`, formData)
    },

    async changeCategory(i) {
      const formData = new FormData()
      formData.append('cat_id', this.selectedCategory[i])
      formData.append('image', '')
      await this.axios.post(`/api/admin/categories/popular/${this.popularCategories[i].id}`, formData)
    },
  },
}
</script>

<style scoped>
.img-placeholder {
  display: flex;
  flex-direction: column;
  height: 150px;
  align-items: center;
  justify-content: center;
}
.img-del-button {
  position: absolute !important;
  top: 0;
  right: 0;
  z-index: 1;
}
</style>
